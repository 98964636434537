import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Maintainance from "./Components/Maintainance";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Header />
    <Maintainance />
    <Footer />
  </React.StrictMode>
);

reportWebVitals();
