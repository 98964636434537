import React from "react";
import Maintainance from "./Maintainance";
import logo from "../images/logo-02.png";


const Header = () => {
  return (
    <>
      <div className="page-width">
        <nav>
          {/* <!-- <button className="navbar-toggler collapsed" type="button">
                    <span className="navbar-toggler-icon"></span>
                </button> --> */}

          <div className="logo">
            <a href="#home">
              <img src={logo} alt="Logo" />
            </a>
          </div>
          <ul className="nav-links">
            {/* <!-- <li><a href="#Home">Home</a></li>
                    <li><a href="#portfolio">Portfolio</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#contact">Contact us</a></li>
                    <li><a href="#blog">Blog</a></li> --> */}

            {/* <!-- <li><a href="#register" className="btn">Register</a></li> --> */}
            <li>
              <a href={Maintainance} className="btn" disabled>
                Subscribe
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
