import React from "react";
import bolt1 from "../images/bolt1.svg";
import maintainance from "../images/website_maintenence_main.svg";
import bolt2 from "../images/bolt2.svg";
import bolt4 from "../images/bolt4.svg";
import bolt5 from "../images/bolt5.svg";
import bolt6 from "../images/bolt6.svg";

const Maintainance = () => {
  return (
    <>
      <section className="maintainance">
        <div className="flex-container align-items-center">
          <div className="page-width">
            <h2 className="maintainance_title">Website is under construction</h2>
            <div className="maintainance_details">
              <p>
                We are currently working on enhancing our browsing experience.
                Our website is undergoing maintainance and will be back soon
                with exciting updates and improvements. Thank you for your
                patience and understanding.
              </p>
            </div>
            <div className="maintenance-artwork">
              <img
                className="main-img"
                src={maintainance}
                alt="maintainance_cover"
                height="400"
                width="400"
              />
              <div className="animated-bolts left">
                <img className="bolt_1 spinner" src={bolt1} alt="bolt1" />
                <img className="bolt_2 spinner" src={bolt2} alt="bolt2" />
                <img className="bolt_3 spinner" src={bolt2} alt="bolt2" />
              </div>

              <div className="animated-bolts right">
                <img className="bolt_4 spinner" src={bolt4} alt="bolt4" />
                <img className="bolt_5 spinner" src={bolt5} alt="bolt5" />
                <img className="bolt_6 spinner" src={bolt6} alt="bolt6" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Maintainance;
